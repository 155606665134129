<template>
  <v-app-bar app color="white" light elevate-on-scroll>
    <v-app-bar-nav-icon
      @click="toggleDrawer()"
      class="hidden-md-and-up utama--text"
    ></v-app-bar-nav-icon>
    <v-icon class="mx-5 hidden-md-and-up" color="utama"
      >mdi-chemical-weapon</v-icon
    >
    <v-icon class="mx-5 hidden-md-and-down" size="40" color="utama"
      >mdi-chemical-weapon</v-icon
    >
    <v-toolbar-title class="utama--text font-weight-black">
      {{ title }} -
      <span class="font-weight-light">Account</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu bottom min-width="200px" rounded offset-y v-if="session">
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-icon color="utama">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar size="100">
              <v-img :src="session.foto" />
            </v-avatar>
            <div class="my-3">
              <h5>{{ session.nama }}</h5>
              <span class="caption">{{ session.nipBaru }}</span>
            </div>
            <v-divider></v-divider>
            <v-btn depressed text block @click="logOut()"> Sigin Out </v-btn>
            <v-divider class="mb-2"></v-divider>
            <v-btn depressed text small block disabled>
              Versi : {{ appVersion }}
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { version } from "../../package";

import Cookie from "@/helper/cookie.js";
import drawerState from "@/store/drawer";

export default {
  data: () => ({
    appVersion: version,
    session: "",
    token: "",

    title : process.env.VUE_APP_OPDNAME
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.token = await Cookie.get("token")
  },

  methods: {
    toggleDrawer() {
      drawerState.commit("toggle", !drawerState.state.drawer);
    },

    logOut() {
      Cookie.delete()
      localStorage.clear()
      window.location.href = process.env.VUE_APP_NEWLOGIN
    },
  },
};
</script>
